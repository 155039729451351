import { render, staticRenderFns } from "./delte.vue?vue&type=template&id=15fdd325&scoped=true"
import script from "./delte.vue?vue&type=script&lang=js"
export * from "./delte.vue?vue&type=script&lang=js"
import style0 from "./delte.vue?vue&type=style&index=0&id=15fdd325&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15fdd325",
  null
  
)

export default component.exports